import { Routes, Route, useNavigationType, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import { useEffect } from 'react';
import ErrolPage from './pages/enroll/EnrollPage';
import Backoffice from 'pages/backoffice/Backoffice';
import { Policies } from 'pages/policies/Policies';
import { Terms } from 'pages/terms/terms';
import { ForgotPasswordPage } from 'pages/fogot-password/ForgotPassword'; //TODO Esta mal escrito fogot password....
import { BackofficeTermsPolicies } from 'pages/backoffice-terms-policies/BackofficeTermsPolicies';
import { RouteGuard } from 'RouteGuard';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    switch (pathname) {
      case '/':
        title = '';
        metaDescription = '';
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route
        path='/'
        element={<Home />}
      />

      <Route
        path='/enroll'
        element={<ErrolPage />}
      />

      <Route
        path='/backoffice'
        element={<Backoffice />}
      />
      <Route
        path='/backoffice-terms-policies'
        element={
          <RouteGuard>
            <BackofficeTermsPolicies />
          </RouteGuard>
        }
      />

      <Route
        path='/policies'
        element={<Policies />}
      />
      <Route
        path='/terms'
        element={<Terms />}
      />
      <Route
        path='/reset_password'
        element={<ForgotPasswordPage />}
      />
    </Routes>
  );
}
export default App;
