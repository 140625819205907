// EnrollmentListTable.tsx
import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Card,
  Box,
  TablePagination,
  Typography,
  Avatar,
  Stack,
  Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

interface Enrollment {
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  phone: string;
}

interface EnrollmentListTableProps {
  data: Enrollment[];
  requestInfo: any;
  onApprove: (index: number) => void;
  onDeny: (index: number) => void;
  setCurrentEnrrol: (index: any) => void;
}

const useMobileScreen = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isMobile;
};

const EnrollmentListTable: React.FC<EnrollmentListTableProps> = ({
  data,
  requestInfo,
  onApprove,
  onDeny,
  setCurrentEnrrol,
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const isMobile = useMobileScreen();

  const navigateToTerms = () => {
    navigate('/backoffice-terms-policies', { replace: true });
  };

  const getInitials = (name = '') =>
    name
      .replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join('');

  const handlePageChange = useCallback((event: any, value: any) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event: any) => {
    setRowsPerPage(event.target.value);
  }, []);

  return (
    <>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth='xl'>
          <Stack spacing={3}>
            <Stack
              direction='row'
              justifyContent='space-between'
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography
                  variant='h4'
                  style={{ color: '#fff' }}
                >
                  Request
                </Typography>
                <Typography
                  variant='subtitle2'
                  style={{ color: '#fff' }}
                >
                  Manage your request
                </Typography>
              </Stack>
            </Stack>

            {isMobile ? (
              <div className='card-container'>
                {data.map((user: any, index: any) => (
                  <div
                    className='card'
                    key={index}
                  >
                    <div className='card-content'>
                      <h4>
                        Name: {user.firstName} {user.lastName}
                      </h4>
                      <h4>Email: {user.email}</h4>
                      <p>City: {user.city}</p>
                      <p>Phone: {user.phone || 'N/A'}</p>
                      <p>
                        Date Request:{' '}
                        {moment(requestInfo[index].date_created).format('YYYY-MM-DD hh:mm:ss a') ||
                          'N/A'}
                      </p>
                      <button
                        color='primary'
                        onClick={() => setCurrentEnrrol(user)}
                        style={{
                          marginRight: '8px',
                          marginBottom: '8px',
                          marginTop: '8px',
                          backgroundColor: '#161d28',
                          color: 'white',
                          padding: '8px',
                          borderRadius: '8px',
                        }}
                      >
                        open
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Card>
                <Box sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding='checkbox'></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Date Request</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((customer, index) => {
                        return (
                          <TableRow
                            onClick={() => setCurrentEnrrol(customer)}
                            hover
                            key={customer.email}
                          >
                            <TableCell padding='checkbox'></TableCell>
                            <TableCell>
                              <Stack
                                alignItems='center'
                                direction='row'
                                spacing={2}
                              >
                                <Avatar src={customer.email}>
                                  {getInitials(customer.firstName)}
                                </Avatar>
                                <Typography variant='subtitle2'>
                                  {customer.firstName} {customer.lastName}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>{customer.email}</TableCell>
                            <TableCell>{customer.city}</TableCell>
                            <TableCell>{customer.phone}</TableCell>
                            <TableCell>
                              {moment(requestInfo[index].date_created).format(
                                'YYYY-MM-DD hh:mm:ss a'
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                // variant='contained'
                                color='primary'
                                onClick={() => setCurrentEnrrol(customer)}
                                style={{
                                  marginRight: '8px',
                                  backgroundColor: '#161d28',
                                  color: 'white',
                                }}
                              >
                                open
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>

                {/* <TablePagination
        component="div"
        count={data.length}
        // onPageChange={onPageChange}
        // onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
              </Card>
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default EnrollmentListTable;
