import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { HomeConfig } from 'Config/home.config';

const ContainerHero: FunctionComponent = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/enroll');
  };

  return (
    <>
      <div
        className='body self-stretch flex flex-col py-0 px-20 items-start justify-normal bg-no-repeat bg-[top] text-center text-14xl text-white font-inter lg:items-center lg:justify-start lg:pl-2.5 lg:pr-2.5 lg:box-border md:items-center md:justify-between md:gap-[0px] sm:items-center sm:justify-start'
        style={{
          backgroundImage: `url(${HomeConfig.banner.bannerImg})`,
          backgroundPosition: 'right center',
          backgroundSize: 'contain',
          minHeight: '900px',
        }}
      >
        <nav className='overlay-content m-0 mb-5 self-stretch flex flex-col py-2.5 px-0 items-center justify-center text-left text-smi text-white font-inter lg:w-auto lg:[align-self:unset] lg:items-center lg:justify-center md:w-full md:items-center md:justify-center'>
          <div className=' self-stretch flex flex-row items-center justify-center lg:w-full lg:gap-[30px] lg:items-center lg:justify-between lg:min-w-full lg:max-w-full md:w-full md:flex-row md:items-center md:justify-center'>
            <div className='flex flex-col items-start justify-start lg:flex-1'>
              <img
                className='relative w-auto h-auto max-w-[130px] max-h-[100px] overflow-hidden shrink-0 cursor-pointer sm:max-w-[100px] sm:max-h-[100px] ml-6'
                // className='relative w-[100px] h-[100px] overflow-hidden shrink-0 cursor-pointer sm:self-stretch sm:w-[100%] sm:h-[100%] '
                alt=''
                src={HomeConfig.header.logo}
              />
            </div>
            <nav className='m-0 flex-1 flex flex-row py-0 pr-0 pl-[70px] items-center justify-start gap-[15px] text-left text-mini text-white font-inter lg:flex lg:w-auto lg:[align-self:unset] lg:gap-[10px] lg:items-center lg:justify-center lg:pl-2.5 lg:box-border md:hidden md:pl-0 md:box-border sm:hidden'>
              {HomeConfig.header.menuItems.map((item) => (
                <div className='flex flex-row py-[19px] pr-[13px] pl-4 items-start justify-start gap-[2.05px]'>
                  {/* <div className='menu-label relative lg:flex-1'>{item.name}</div> */}
                </div>
              ))}
            </nav>
            <div className='w-[181px] flex flex-col items-end justify-center gap-[10px] lg:w-auto lg:[align-self:unset] lg:items-end lg:justify-center md:items-end md:justify-center sm:w-auto sm:[align-self:unset] sm:items-center sm:justify-center sm:pr-5 sm:box-border'>
              <div className='self-stretch flex flex-row items-center justify-center gap-[10px] header-button'>
                <div className='flex flex-row items-center justify-end gap-[11px]'>
                  <a
                    className='[text-decoration:none] relative uppercase font-semibold text-[inherit] '
                    onClick={navigateHome}
                    style={{ cursor: 'pointer' }}
                  >
                    Login
                  </a>
                  <div className='relative text-xs font-light'>○</div>
                </div>
                <a className='[text-decoration:none] flex flex-row items-start justify-start text-[inherit]'>
                  <a
                    className='[text-decoration:none] relative uppercase font-semibold text-[inherit]'
                    onClick={navigateHome}
                    style={{ cursor: 'pointer' }}
                  >
                    Register
                  </a>
                </a>
              </div>
            </div>
          </div>
        </nav>

        <div className='overlay-content self-stretch flex flex-col  px-0 items-center justify-center gap-[17px] md:pt-[100px] md:pb-[100px] sm:pb-[20px]  md:box-border sm:flex-col sm:gap-[10px]'>
          <h3 className='header-title self-stretch relative text-[inherit] tracking-[-0.96px] leading-[52.8px] font-light font-inherit [text-shadow:1px_1px_24px_rgba(34,_34,_34,_0.5)] md:text-5xl md:leading-[30px] sm:text-base sm:leading-[24px] sm:self-stretch sm:w-auto'>
            {HomeConfig.banner.bannerTitle.map((item) => (
              <p className='m-0 '>
                {item} <br />
              </p>
            ))}
          </h3>
          <div className='m-0  self-stretch relative font-light font-inherit md:text-5xl md:leading-[30px] sm:text-base sm:leading-[24px] sm:self-stretch sm:w-auto description'>
            <p className='m-0 font-titi text-left'>{HomeConfig.banner.bannerDescription}</p>
            <button
              onClick={navigateHome}
              className='cursor-pointer [border:none] py-[9.389999389648438px] pr-[23.30999755859375px] pl-6 bg-[transparent] self-stretch rounded-81xl [background:linear-gradient(135deg,_#f19126,_rgba(246,_166,_72,_0)),_#E6007E] flex flex-row items-center justify-center md:max-w-[175px] mt-8'
            >
              <div className='flex-1 relative text-mini font-medium font-inter text-white text-center w-28'>
                Enroll Now
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContainerHero;
